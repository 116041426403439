import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrls, post, deleteRequest } from "utils/request";

export const initialState = {
  crusherActivities: {
    loading: false,
    records: [],
    revert: [],
    onRevert: false,
    error: "",
    recordsTotal: "",
    logList: [],
    logListTotal: 0,
  },
};

export const getMDActivitiesCrusher = createAsyncThunk(
  "masterData/crushing-activities",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/crushing-activities/filter`, {
      columns: [
        {
          data: "activity",
          orderable: true,
          search: {
            regex: false,
            value: ""
          },
          searchValue: params.activity || "",
          searchable: true,
        },
        {
          data: "detail",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.detail || "",
          searchable: true,
        },
        {
          data: "subDetail",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.subDetail || "",
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status || "",
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || "",
          searchable: true,
        },
      ],
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      search: {
        regex: false,
        value: params.query || "",
      },
      start: 0,
    });
    return response?.data;
  }
);

export const getMDActivitiesCrusherLogs = createAsyncThunk(
  "masterData/crushing-activities/logs",
  async (params) => {
    const response = await post(`${apiUrls.masterData}/crushing-activities/logs`, {
      draw: params?.pageIndex ? params.pageIndex : 1,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "desc",
        },
      ],
      start: 0,
      length: params?.recordLength ? params.recordLength : 10,
      search: {
        value: "",
        regex: false,
      },
    });
    return response?.data;
  }
);

export const submitMDActivitiesCrusher = createAsyncThunk(
  "masterData/crushing-activities/submit",
  async (data) => {
    let response;

    try {
      response = await post(`${apiUrls.masterData}/crushing-activities/submit`, data);
      return response?.data;
    }
    catch (err) {
      return err;
    }
  }
);

export const deleteMDActivitiesCrusher = createAsyncThunk(
  "masterData/crushing-activities/delete",
  async (id) => {
    let response;

    try {
      response = await deleteRequest(`${apiUrls.masterData}/crushing-activities/${id}`);
      return response?.data;
    }
    catch (err) {
      return err;
    }
  }
);

const mdActivityCrushing = createSlice({
  name: "MASTER_DATA_ACTIVITY",
  initialState,
  reducers: {
    addDataTableActivityCrusher: (state, action) => {
      const payload = action.payload;

      state.crusherActivities?.records.unshift(payload);
    },

    onRemoveDataTableActivityCrusher: (state, action) => {
      const payload = action.payload;

      state.crusherActivities.records.splice(payload.tableIndexRow, 1);
    },

    onChangeTableActivityCrusher: (state, action) => {
      const payload = action.payload;

      state.crusherActivities.records[payload.tableIndexRow][
        payload.tableRowName
      ] = payload.value;

      if (payload.tableRowName === "activity") {
        state.crusherActivities.records[payload.tableIndexRow]["detail"] = "";
      }
    },

    onRevertTableActivityCrusher: (state, action) => {
      const payload = action.payload;

      state.crusherActivities.revert = state.crusherActivities.records;
      if (!payload) state.crusherActivities.onRevert = true;
      else state.crusherActivities.onRevert = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMDActivitiesCrusher.pending, (state, action) => {
      state.crusherActivities.loading = true;
    });
    builder.addCase(getMDActivitiesCrusher.fulfilled, (state, action) => {
      state.crusherActivities.loading = false;
      state.crusherActivities.records = action.payload.list;
      state.crusherActivities.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getMDActivitiesCrusher.rejected, (state, action) => {
      state.crusherActivities.loading = false;
      state.crusherActivities.error = "Invalid get data";
    });

    builder.addCase(
      getMDActivitiesCrusherLogs.fulfilled,
      (state, action) => {
        state.crusherActivities.logList = action.payload.list;
        state.crusherActivities.logListTotal = action.payload.recordsTotal;
      }
    );
    builder.addCase(
      getMDActivitiesCrusherLogs.rejected,
      (state, action) => {
        state.crusherActivities.loading = false;
        state.crusherActivities.error = "Invalid get data";
      }
    );
  },
});

export const mdActivityCrushingSelector = (state) =>
  state.mdActivityCrushing.crusherActivities;
export const {
  onChangeTableActivityCrusher,
  addDataTableActivityCrusher,
  onRevertTableActivityCrusher,
  onRemoveDataTableActivityCrusher,
} = mdActivityCrushing.actions;

export default mdActivityCrushing.reducer;
