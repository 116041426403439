import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, getAPIEndpoint, apiUrls, post } from 'utils/request';
import axios from 'axios';


export const initialState = {
  loading: false,
  data: [],
  recordsTotal: 0,
  error: '',
  filter: {
    cpOrCV: [],
    shift: [
      { value: 'Day', label: 'Day' },
      { value: 'Night', label: 'Night' }
    ],
    locationCode: [
      { value: 'bib', label: 'BIB' },
      { value: 'dir', label: 'DIR' },
      { value: 'dll', label: 'DLL' }
    ],
    module: [
      { value: 'crushing', label: 'Crushing' },
      { value: 'loading', label: 'Loading' },
      { value: 'p2h', label: 'P2H' },
      { value: 'jetty', label: 'Jetty' }
    ],
    type: [
      { value: 'crushing', label: 'Crushing' },
      { value: 'loading', label: 'Loading' },
      { value: 'p2h', label: 'P2H' },
      { value: 'jetty', label: 'Jetty' }
    ],
    jettyType: [
      { label: "Transshipment", value: "Transshipment" },
      { label: "Direct Barge", value: "Direct Barge" }
    ],
    loadingPosition: [
      { label: "West", value: "West" },
      { label: "East", value: "East" }
    ]
  },
  statusAction: {
    deleteSingleFile: 'idle',
    //deleteAllFile: 'idle',
    downloadFile: 'idle',
    // exportModuleLoading: 'idle',
    // exportModuleCrushing: 'idle',
    // exportModuleP2H: 'idle',
    exportModuleExcel: 'idle',
    // exportModuleJetty: 'idle',
  },
  contractorByArea: {
    records: [],
    loading: false,
    error: ""
  },
};

export const insertOptionAll = (data) => {
  return [{ value: 'all', label: 'All' }, ...data];
}

const excludeEmptyAndAll = (params) => {
  const result = {};
  Object.keys(params).forEach(key => {
    if (params[key] && params[key] !== 'all') {
      result[key] = params[key];
    }
  });

  return result;
}

const generateCPOptions = (data) => {
  return data.map(item => ({ value: item.plant, label: item.plant }));
}

const generateCVOptions = (data) => {
  const temp = data.map(item => item.jettyLoading) || [];
  const unique = [...new Set(temp)];
  const result = unique.map(item => ({ value: item, label: item }));
  return result;
}

export const fetchPitReport = createAsyncThunk(
  'pit-report',
  async (params) => {
    const { draw, length, search } = params;
    //console.log(params)
    const response = await post(`${apiUrls.pitReport}/list`, {
      columns: [
        {
          data: 'name',
          orderable: true,
          search: {
            regex: false,
            value: ''
          },
          searchValue: '',
          searchable: true
        },
        {
          data: 'type',
          orderable: true,
          search: {
            regex: false,
            value: ''
          },
          searchValue: "",
          searchable: true
        },
      ],
      draw: draw || 1,
      length: length || 50,
      search: {
        regex: false,
        value: search || ''
      },
      start: 0
    });
    return response.data;
  }
);

export const fetchContractorByArea = createAsyncThunk(
  `master-data/area-by-name`,
  async (params) => {
    const { area } = params;
    const response = await get(`${apiUrls.masterData}/areas/names/${area}/contractors`);
    return response?.data;
  }
);

export const getMasterDataCrusher = createAsyncThunk(
  "masterData/crushers",
  async (params) => {
    const response = await post(`${apiUrls.mdPortCrusher}/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: false,
        },
        {
          data: "port",
          orderable: true,
          search: {
            regex: true,
            value: "",
          },
          searchValue: params.port || "",
          searchable: true,
        },
        {
          data: "plant",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "designCapacity",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.designCapacity || "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status,
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || '',
          searchable: true,
        },
      ],
      search: {
        regex: false,
        value: params.query || "",
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      start: 0,
    });
    return response?.data;
  }
);

export const getMasterDataJetty = createAsyncThunk(
  "masterData/jetties",
  async (params) => {
    const response = await post(`${apiUrls.mdPortJetty}/filter`, {
      columns: [
        {
          data: "id",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "port",
          orderable: true,
          search: {
            regex: true,
            value: "",
          },
          searchValue: params.port || "",
          searchable: true,
        },
        {
          data: "jettyLoading",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "designCapacity",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.designCapacity || "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "entityStatus",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.status,
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: true,
            value: params.statusRegex || "",
          },
          searchValue: params.dataStatus || '',
          searchable: true,
        },
      ],
      search: {
        regex: true,
        value: params.query || "",
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.recordLength ? params.recordLength : -1,
      start: 0,
    });
    return response?.data;
  }
);

const downloadFileFromBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

const removeTwoCharacters = (str) => {
  if (typeof str !== 'string') return str;

  const extension = str.split('.').pop();
  const fileName = str.split('.').shift();
  const newFileName = fileName.slice(0, -2);

  return `${newFileName}.${extension}`;
}

export const downloadFile = createAsyncThunk(
  'download-file',
  async (params) => {
    const { urlFile, fileName } = params;
    //console.log(params)
    try {
      const response = await get(`${apiUrls.pitReport}/download?fileName=${fileName}`)


      // const blob = new Blob([response.data], { type: 'application/octet-stream' });
      // downloadFileFromBlob(blob, removeTwoCharacters(filename));
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const exportModuleExcel = createAsyncThunk('exportExcel', async (params) => {
  // const { endDate, shift, startDate, contractor, module } = params

  try {
    const response = await post(`${apiUrls.pitReport}/export-excel`, params)
    // const blob = new Blob([response.data], { type: 'application/octet-stream' });
    // downloadFileFromBlob(blob, removeTwoCharacters(filename));
    //console.log("response", response)
    return response.data;
  } catch (error) {
    throw error
  }

})

export const exportModuleLoading = createAsyncThunk(
  "actual-port-jetty/exportExcel",
  async (params) => {
    const object = {
      crushingPlant: params.cpOrCV,
      endDate: params.endDate,
      jetty: params.cpOrCV,
      shift: params.shift,
      startDate: params.startDate,
    };

    const response = await post(
      getAPIEndpoint("actual-port", "v1", "/report/loading-blc/export-excel"),
      excludeEmptyAndAll(object)
    );
    return response?.data;
  }
);

export const exportModuleP2H = createAsyncThunk(
  "actual-port-p2h/exportExcel",
  async (params) => {
    const object = {
      crushing: params.cpOrCV,
      endDate: params.endDate,
      shift: params.shift,
      startDate: params.startDate,
    };

    const response = await post(
      getAPIEndpoint("actual-port", "v1", "/report/p2h/export-excel"),
      excludeEmptyAndAll(object)
    );
    return response?.data;
  }
);

export const exportModuleCrushing = createAsyncThunk('crushing/exportExcel', async (params) => {
  const object = {
    crushingPlant: params.cpOrCV,
    endDate: params.endDate,
    shift: params.shift,
    startDate: params.startDate
  };

  const response = await post(
    getAPIEndpoint('actual-port', 'v1', '/report/crushing/export-excel'),
    excludeEmptyAndAll(object)
  );
  return response?.data;
})

export const exportModuleJetty = createAsyncThunk('jetty/exportExcel', async (params) => {
  const object = {
    endDate: params.endDate,
    startDate: params.startDate,
    shift: params.shift,
    loadingPosition: params.loadingPosition,
    type: params.jettyType
  }

  const response = await post(
    getAPIEndpoint('actual-port', 'v1', '/report/loading-jetty/export-excel'),
    excludeEmptyAndAll(object)
  );
  return response?.data;
})

export const deleteSingleFile = createAsyncThunk(
  'delete-file',
  async (params) => {
    const { fileName, id } = params;
    try {
      const response = await axios.delete(
        `${apiUrls.pitReport}/export-excel?fileName=${fileName}&id=${id}`
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);


const pitReportSlice = createSlice({
  name: 'pit-report',
  initialState,
  reducers: {
    resetPortReport: () => initialState,
    resetStatusAction: (state, action) => {
      state.statusAction.deleteSingleFile = 'idle';
      state.statusAction.downloadFile = 'idle';
      state.statusAction.exportModuleLoading = 'idle';
      state.statusAction.exportModuleCrushing = 'idle';
      state.statusAction.exportModuleP2H = 'idle';
      state.statusAction.exportModuleJetty = 'idle';
      state.statusAction.exportModuleExcel = 'idle';
    }
  },
  extraReducers: {

    [fetchContractorByArea.pending]: (state, action) => {
      state.contractorByArea.loading = true;
    },
    [fetchContractorByArea.fulfilled]: (state, action) => {
      state.contractorByArea.records = action.payload;
    },
    [fetchContractorByArea.rejected]: (state, action) => {
      state.contractorByArea.loading = false;
      state.contractorByArea.error = action.error;
    },
    [fetchPitReport.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPitReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data.row;
      state.recordsTotal = action?.payload?.data.total_rows;
    },
    [fetchPitReport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    // [getMasterDataCrusher.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [getMasterDataCrusher.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.filter.cpOrCV = generateCPOptions(action?.payload?.list);
    // },
    // [getMasterDataCrusher.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message;
    // },
    // [getMasterDataJetty.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [getMasterDataJetty.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.filter.cpOrCV = generateCVOptions(action?.payload?.list);
    // },
    // [getMasterDataJetty.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message;
    // },
    [deleteSingleFile.pending]: (state) => {
      state.statusAction.deleteSingleFile = 'loading';
    },
    [deleteSingleFile.fulfilled]: (state) => {
      state.statusAction.deleteSingleFile = 'success';
    },
    [deleteSingleFile.rejected]: (state) => {
      state.statusAction.deleteSingleFile = 'failed';
    },
    // [deleteAllFile.pending]: (state) => {
    //   state.statusAction.deleteAllFile = 'loading';
    // },
    // [deleteAllFile.fulfilled]: (state) => {
    //   state.statusAction.deleteAllFile = 'success';
    // },
    // [deleteAllFile.rejected]: (state) => {
    //   state.statusAction.deleteAllFile = 'failed';
    // },
    [downloadFile.pending]: (state) => {
      state.statusAction.downloadFile = 'loading';
    },
    [downloadFile.fulfilled]: (state) => {
      state.statusAction.downloadFile = 'success';
    },
    [downloadFile.rejected]: (state) => {
      state.statusAction.downloadFile = 'failed';
    },
    [exportModuleExcel.pending]: (state) => {
      state.loading = true
      state.statusAction.exportModuleExcel = 'loading';
    },
    [exportModuleExcel.fulfilled]: (state, action) => {
      state.loading = false
      state.statusAction.exportModuleExcel = 'success';
      state.data = action?.payload?.data.row;
      state.recordsTotal = action?.payload?.data.total_rows;
    },
    [exportModuleExcel.rejected]: (state) => {
      state.loading = false
      state.statusAction.exportModuleExcel = 'failed';
    },
  }
});

export const pitReportSelector = (state) => state?.pitReport;
export const contractorByArea = (state) =>
  state.payload.contractorByArea;
export const { resetPortReport, resetStatusAction } = pitReportSlice.actions;
export default pitReportSlice.reducer;
