import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { cloneDeep, constant, isInteger, sum, sumBy } from 'lodash';
import moment from 'moment';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';
import { DATASOURCE_VALUE, getUsername, calculateShifRitaseAndBcm, addDataChartContractor, addDataChartPit, formatNumber } from 'utils/helper';

export const initialState = {
  coalGetting: {
    loading: false,
    records: [],
    totalRecords: "",
    error: "",
    options: {
      pit: [],
      contractor: [],
    }
  },
  coalGettingDetail: {
    rainSlippery: {
      id: null,
      forecastTotalRainSlipperyList: [],
      actualTotalRainSlipperyList: []
    },
    production: {
      id: null,
      pitToRom: [],
      pitToPort: {
        total: 0,
      },
      documentList: [],
      totalMaterial: [],
      allTotalMaterial: 0,
      getLatest: false,
    },
    maintenanceUnit: {
      id: null,
      maintenanceDataList: []
    },
    userName: "",
    pit: "",
    shift: null,
    reportDate: null,
  },

  getDetailData: false,

  documentList: [],
  payloadLatest: [],
  payloadOlder: [],
  versionList: [],
  coalGettingCreate: {
    loading: false,
    records: [],
    error: ""
  },

  activeIdDetail: 'create-report',
  loading: false,
  error: "",
  response: "",
  bcmSummary: {
    loading: false,
    records: []
  },

  bcmSummaryDetail: {
    loading: false,
    records: {}
  },
  bcmSummaryDetailBar: {
    loading: false,
    records: []
  }

};


export const getCoalBcm = createAsyncThunk('coalgetting/bcm-summary', async (params) => {
  const { contractor = params.contractor !== "" ? params.contractor : "All", month, pit = 'All', year } = params;

  const response = await post(
    `${apiUrls.coalGetting}/bcm-summary?contractor=${contractor}&month=${month}&pit=${pit}&year=${year}`);
  return response?.data;
});

export const getPitOptions = createAsyncThunk('wasteRemoval/get-pit-options', async (params) => {
  const response = await post(`${apiUrls.masterData}/contractor-locations/filter`, {
    "columns": [
      {
        "data": "id",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "location.name",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "contractor.name",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "bibNotes",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "entityStatus",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "dataStatus",
        "orderable": true,
        "search": {
          "regex": true,
          "value": ""
        },
        "searchValue": 3,
        "searchable": true
      },
      {
        "data": "location.area.name",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchable": true
      },
      {
        "data": "contractor.code",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchable": true
      }
    ],
    "draw": 1,
    "length": 999999,
    "search": {
      "regex": false,
      "value": ""
    },
    "start": 0
  });
  return response?.data;
});

export const getContractorOptions = createAsyncThunk('wasteRemoval/get-loading-point-options', async (params) => {
  const response = await get(`${apiUrls.masterData}/areas/names/Pit/contractors`);
  return response?.data;
});


export const getCoalGetting = createAsyncThunk('coalGetting', async (params) => {
  const response = await post(`${apiUrls.coalGetting}/filter`, {
    "columns": [
      {
        "data": "reportId",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": params?.reportId || '',
        "searchable": true
      },
      {
        "data": "createdAt",
        "orderable": true,
        "search": {
          "regex": true,
          "value": ""
        },
        "searchValue": params?.date || '',
        "searchable": true
      },
      {
        "data": "shift",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": params?.shift || '',
        "searchable": true
      },
      {
        "data": "version",
        "orderable": true,
        "search": {
          "regex": false,
          "value": "string"
        },
        "searchValue": params?.version || '',
        "searchable": true
      },
      {
        "data": "pit",
        "orderable": true,
        "search": {
          "regex": params?.pit ? true : false,
          "value": ""
        },
        "searchValue": params?.pit || '',
        "searchable": true
      },
      {
        "data": "contractor",
        "orderable": true,
        "search": {
          "regex": params?.contractor ? true : false,
          "value": ""
        },
        "searchValue": params?.contractor || '',
        "searchable": true
      },
      {
        "data": "dataStatus",
        "orderable": true,
        "search": {
          "regex": params?.dataStatus === DATASOURCE_VALUE.waitingApproval || params?.dataStatus >= 20 ? true : false,
          "value": ""
        },
        "searchValue": params?.dataStatus || '',
        "searchable": true
      },
      {
        "data": "reportDate",
        "orderable": true,
        "search": {
          "regex": true,
          "value": ""
        },
        "searchValue": params.reportDate || "",
        "searchable": true
      }
    ],
    "search": {
      "regex": false,
      "value": params.query || ""
    },
    "draw": params?.draw || 1,
    "length": params?.length || 50,
  });
  return response?.data;
});

export const getVersionList = createAsyncThunk('coalGetting/get-version', async (params) => {
  const { reportId } = params;
  const response = await get(`${apiUrls.coalGetting}/${reportId}/versions`);
  return response?.data;
});

export const getReportByVersion = createAsyncThunk('coalGetting/getByVersion', async (params) => {
  const { reportId, version } = params;
  const response = await get(`${apiUrls.coalGetting}/${reportId}/versions/${version}`);
  return response?.data;
})

export const setActivatedVersion = createAsyncThunk('coalGetting/setActiveVersion', async (params) => {
  const { reportId, version } = params;
  const response = await patch(`${apiUrls.coalGetting}/${reportId}/versions/${version}`);
  return response?.data;
});

export const getLatestPayload = createAsyncThunk('default-payload-coal/get-latest', async (params) => {
  const { contractorCode } = params;
  const response = await get(`${apiUrls.payloadCoalGetting}/latest-detail?contractorCode=${contractorCode}`);
  return response?.data;
});

export const getDurationWaste = createAsyncThunk('coalGetting/get-duration', async (params) => {
  const response = await get(`${apiUrls.coalGetting}/slippery-coal/duration`);
  return response?.data;
});

export const addCoalGetting = createAsyncThunk('coalGetting/add', async (params) => {
  const response = await post(`${apiUrls.coalGetting}/save?userName=${getUsername()}`, {
    "contractor": params.contractor,
    "pit": params.pit,
    "shift": params.shift,
    "userName": params.userName
  });
  return response?.data;
});

export const setVersionCoalGetting = createAsyncThunk('coalGetting/set-version', async (params) => {
  const response = await patch(`${apiUrls.coalGetting}/${params.id}/versions/${params.version}?userName=${getUsername()}`);
  return response?.data;
});

export const saveCoalGetting = createAsyncThunk('coalGetting/save', async (params) => {
  console.log('😒 | file: reducer.js:202 | params:', params);
  const newActualTotalRainSlipperyList = params.actualTotalRainSlipperyList.map(dt => {
    return {
      ch: typeof dt.ch === 'string' ? parseFloat(dt.ch.replace(",", ".")) : dt.ch,
      endRain: dt.endRain,
      endSlippery: dt.endSlippery,
      startRain: dt.startRain,
      startSlippery: dt.startSlippery,
      type: dt.type,
      durationRain: typeof dt.durationRain === 'string' ? parseFloat(dt.durationRain.replace(",", ".")) : dt.durationRain,
      durationSlippery: typeof dt.durationSlippery === 'string' ? parseFloat(dt.durationSlippery.replace(",", ".")) : dt.durationSlippery,
    }
  })

  const response = await put(`${apiUrls.coalGetting}/${params.id}/save?userName=${getUsername()}`, {
    "contractor": params.contractor,
    "rainSlippery": {
      "id": params.slippery,
      "forecastTotalRainSlipperyList": params.forecastTotalRainSlipperyList || [],
      "actualTotalRainSlipperyList": newActualTotalRainSlipperyList || []
    },
    "pit": params.pit,
    "production": {
      "id": params.production,
      "pitToRom": params.productionData || [],
      "pitToPort": {
        "total": params.total || 0
      },
      "documentList": []
    },
    "maintenanceUnit": {
      "id": params.maintenanceUnit,
      "maintenanceDataList": params.maintenanceDataList || []
    },
    "shift": params.shift,
    "reportDate": params.reportDate,
    "updatedAt": new Date()
  });
  return response?.data;
});

export const submitCoalGetting = createAsyncThunk('coalGetting/submit', async (payload) => {
  console.log('😒 | file: reducer.js:241 | payload:', payload);
  const userName = getUsername();
  const url = !parseInt(payload.id) ? `${apiUrls.coalGetting}/${payload.action}?userName=${userName}` :
    `${apiUrls.coalGetting}/${payload.id}/${payload.action}?userName=${userName}`

  let object = {}

  if (!isInteger(payload.id)) {
    const newActualTotalRainSlipperyList = payload.rainSlippery.actualTotalRainSlipperyList.map(dt => {
      return {
        ch: typeof dt.ch === 'string' ? parseFloat(dt.ch.replace(",", ".")) : dt.ch,
        endRain: dt.endRain,
        endSlippery: dt.endSlippery,
        startRain: dt.startRain,
        startSlippery: dt.startSlippery,
        type: dt.type,
        durationRain: typeof dt.durationRain === 'string' ? parseFloat(dt.durationRain.replace(",", ".")) : dt.durationRain,
        durationSlippery: typeof dt.durationSlippery === 'string' ? parseFloat(dt.durationSlippery.replace(",", ".")) : dt.durationSlippery,
      }
    })

    const newRainSlippery = {
      actualTotalRainSlipperyList: newActualTotalRainSlipperyList,
      forecastTotalRainSlipperyList: payload.rainSlippery.forecastTotalRainSlipperyList
    }

    object = {
      contractor: payload.contractor,
      pit: payload.pit,
      shift: payload.shift,
      rainSlippery: newRainSlippery,
      production: payload.production,
      maintenanceUnit: payload.maintenanceUnit,
      reportDate: payload.reportDate,
    }
  }
  else {
    if (payload.isAdmin) {
      object = {
        notes: payload.notes
      }
    }
    else {
      object = {
        contractor: payload.contractor,
        id: payload.id,
        pit: payload.pit,
        shift: payload.shift,
        rainSlippery: payload.rainSlippery,
        production: payload.production,
        maintenanceUnit: payload.maintenanceUnit,
        reportDate: payload.reportDate,
      }
    }
  }

  let response;

  if (payload.method == "put") response = await put(url, object);
  else if (payload.method == "post") response = await post(url, object);

  return response?.data;
});

export const deleteCoalGetting = createAsyncThunk('coalGetting/delete', async (params) => {
  const response = await deleteRequest(`${apiUrls.coalGetting}/${params}`);
  return response?.data;
});

export const getDetailCoalGetting = createAsyncThunk('coalGetting/detail', async (params) => {
  const { id } = params;
  const response = await get(`${apiUrls.coalGetting}/${id}`);
  return response?.data;
});

export const getDetailCoalGettingUpdateAt = createAsyncThunk('coalGetting/detail-update', async (params) => {
  const { id } = params;
  const response = await get(`${apiUrls.coalGetting}/${id}`);
  return response?.data;
});

export const getLatestProductionByContractorName = createAsyncThunk('coalGetting/production/latest', async (params) => {
  const { contractorName } = params;
  const response = await get(`${apiUrls.coalGetting}/productions/contractor-name/${contractorName}`);
  // console.log("response", response)
  if (response?.data?.production?.pitToRom.length > 0) {
    // const dtParents = response.data.production.pitToRom.map((dt, index) => {
    //   return {
    //     ...dt,
    //     rowNumber: dt.rowNumber,
    //     subRows: dt.subRows.map((sr, rowIndex) => {
    //       return {
    //         ...sr,
    //         fleet: dt.fleet,
    //         rowNumber: dt.rowNumber,
    //         subRowKey: `${index}_${rowIndex}`
    //       }
    //     })
    //   }
    // });
    const dtParents = response.data.production.pitToRom
      //  .filter((_, index) => index !== 0)  // Filter out index 0
      .map((dt, index) => {
        return {
          ...dt,
          rowNumber: dt.rowNumber,
          subRows: dt.subRows.map((sr, rowIndex) => {
            return {
              ...sr,
              fleet: dt.fleet,
              rowNumber: dt.rowNumber,
              subRowKey: `${index}_${rowIndex}`
            };
          })
        };
      });

    console.log("dtParents", dtParents)
    return { production: dtParents, pit: response?.data?.pit };
  }
  return { production: [], pit: {} };
})

export const postDataDocumentLIst = createAsyncThunk('coalGetting/create', async (params) => {
  const response = await post(`${apiUrls.coalGetting}/productions${params.id}/files`);
  return response?.data
})

export const getDetailCoalByVersion = createAsyncThunk('coalGetting/get-detail-by-version', async (params) => {
  const { reportId, version } = params;
  const response = await get(
    `${apiUrls.coalGetting}/${reportId}/versions/${version}`
  );
  return response?.data;
});

export const getContractor = createAsyncThunk('get-contractor', async (params) => {
  const response = await post(`${apiUrls.masterData}/contractors/filter`, {
    "columns": [
      {
        data: 'name',
        orderable: true,
        search: {
          regex: false,
          value: '',
        },
        searchValue: params.name,
        searchable: true,
      },
    ],
    "search": {
      "regex": false,
      "value": params.query || ""
    },
    "draw": 1,
    "length": 1,
  });
  return response?.data;
});

export const getPit = createAsyncThunk('get-pit', async (params) => {
  const response = await post(`${apiUrls.masterData}/locations/filter`, {
    "columns": [
      {
        data: 'area.name',
        orderable: true,
        search: {
          regex: false,
          value: '',
        },
        searchValue: 'Pit',
        searchable: true,
      },
      {
        data: 'name',
        orderable: true,
        search: {
          regex: false,
          value: '',
        },
        searchValue: params.name,
        searchable: true,
      },
    ],
    "search": {
      "regex": false,
      "value": params.query || ""
    },
    "draw": 1,
    "length": 1,
  });
  return response?.data;
});

export const intervensiCoalGetting = createAsyncThunk(
  'coalGetting/intervensi',
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.coalGetting}/intervensi/user-role?moduleName=CoalGetting`, params);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

const coalGettingSlice = createSlice({
  name: 'COAL_GETTING',
  initialState,
  reducers: {
    getCoalGettingDetail: (state, action) => {
      state.coalGettingDetail = action.payload;
    },

    activeIdDetail: (state, action) => {
      state.activeIdDetail = action.payload;
    },

    createCoalGetting: (state, action) => {
      state.coalGettingCreate.records = action.payload;
    },

    clearCoalGettingDetail: (state, action) => {
      // state.coalGettingDetail = {
      //   rainSlippery: {
      //     forecastTotalRainSlipperyList: [],
      //     actualTotalRainSlipperyList: []
      //   },
      //   production: {
      //     data: [],
      //     documentList: []
      //   },
      //   maintenanceUnit: {
      //     maintenanceDataList: []
      //   },
      //   userName: ""
      // };
      // state.documentList = []

      state.coalGettingDetail = {
        rainSlippery: {
          id: null,
          forecastTotalRainSlipperyList: [],
          actualTotalRainSlipperyList: []
        },
        production: {
          id: null,
          pitToRom: [],
          pitToPort: {
            total: 0,
          },
          documentList: [],
          totalMaterial: [],
          allTotalMaterial: 0,
          getLatest: false,
        },
        maintenanceUnit: {
          id: null,
          maintenanceDataList: []
        },
        userName: "",
        pit: "",
        shift: null,
        reportDate: null,
      }

      state.getDetailData = false
      state.documentList = []
    },

    /**
     * * ADD ACTUAL TOTAL RAIN *
     * Todo: to add actual total rain table on waste removal slippery page
     * @param action
     */
    addActualTotalRain: (state, action) => {
      state.coalGettingDetail['rainSlippery']['actualTotalRainSlipperyList'].push(action.payload);
    },

    /**
     * * ADD DATA TABLE PRODUCTION *
     * Todo: to add table on waste removal production page
     * @param action
     */
    addDataTableProduction: (state, action) => {
      //   console.log("action.payload", action.payload)
      state.coalGettingDetail?.production?.pitToRom.push(action.payload);
    },

    /**
     * * ADD DATA TABLE PRODUCTION *
     * Todo: to add table on waste removal production page
     * @param action
     */
    addUploadDataTableProduction: (state, action) => {

      const processProductionData = (payload) => {
        const { sheetProduction, equipmentDetailList, listId, equipmentDetailSubList, dumpingPointLocation, listDumpingLocation, loadingPointLocation, materialList } = payload;
        const dtParentProduction = sheetProduction.filter(el => el.parentRow);
        const dtSrProduction = sheetProduction.filter(el => !el.parentRow);
        let renewFleet = 0;

        return dtParentProduction.map(dt => {
          renewFleet++;
          const listSubRow = dtSrProduction.filter(el => el.fleet === dt.fleet);

          const subRows = listSubRow.length > 0 ? listSubRow.map(sr => ({
            ...sr,
            rowNumber: dt.fleet,
            fleet: renewFleet,
            unit: equipmentDetailSubList.find(el => el.equipmentClass === sr.unit) || "",
            id: "",
            dumpingPoint: dumpingPointLocation.find(el => el.name === sr.dumpingpoinsublocationt) || "",
            loadingPoint: listDumpingLocation.find(el => el.name === sr.dumpingpoinlocationt) || "",
            loadingPointSubLocation: loadingPointLocation.find(el => el.name === sr.loadingpointsublocation) || "",
            material: materialList.find(el => el.name === sr.materail) || "",
          })) : [{
            fleet: renewFleet,
            rowNumber: dt.fleet,
            unit: "",
            id: "",
            status: "",
            material: materialList[0] || "",
            location: "",
            ritase: 0,
            bcm: 0,
            productivity: "",
            shif1: 0,
            shif2: 0,
            shif3: 0,
            shif4: 0,
            shif5: 0,
            shif6: 0,
            shif7: 0,
            shif8: 0,
            shif9: 0,
            shif10: 0,
            shif11: 0,
            shif12: 0,
            quantity: 0,
          }];

          return {
            ...dt,
            rowNumber: dt.fleet,
            fleet: renewFleet,
            unit: equipmentDetailList.find(el => el.equipmentClass === dt.unit) || "",
            id: listId.find(el => el.equipmentDetailId === dt.equipmentdetailid) || "",
            status: { job: "Production" },
            bcm: 0,
            subRows,
          };
        });
      };

      const payload = action.payload;
      const dtProduction = [];
      const dtParentProduction = payload.sheetProduction.filter(el => el.parentRow === true);
      const dtSrProduction = payload.sheetProduction.filter(el => el.parentRow === false);
      let renewFleet = 0;
      dtParentProduction.forEach(dt => {
        renewFleet += 1;
        const listSubRow = dtSrProduction.filter(el => el.fleet === dt.fleet)
        const tempData = {
          ...dt,
          rowNumber: dt.fleet,
          fleet: renewFleet,
          unit: payload.equipmentDetailList.find(el => el.equipmentClass === dt.unit) || "",
          id: payload.listId.find(el => el.equipmentDetailId === dt.equipmentdetailid) || "",
          status: { job: "Production" },
          bcm: 0,
          // ewh: parseFloat(dt.ewh).toFixed(2),
          subRows: (listSubRow?.length > 0) ? dtSrProduction.filter(el => el.fleet === dt.fleet).map(sr => {
            return {
              ...sr,
              rowNumber: dt.fleet,
              fleet: renewFleet,
              unit: payload.equipmentDetailSubList.find(el => el.equipmentClass === sr.unit) || "",
              id: "",
              dumpingPoint: payload.dumpingPointLocation.find(el => el.name === sr.dumpingpoinsublocationt) || "",
              loadingPoint: payload.listDumpingLocation.find(el => el.name === sr.dumpingpoinlocationt) || "",
              loadingPointSubLocation: payload.loadingPointLocation.find(el => el.name === sr.loadingpointsublocation) || "",
              material: payload.materialList.find(el => el.name === sr.materail) || "",
            }
          }) :
            [{
              fleet: renewFleet,
              rowNumber: dt.fleet,
              unit: "",
              id: "",
              status: "",
              material: payload.materialList[0] || "",
              location: "",
              ritase: 0,
              bcm: 0,
              productivity: "",
              shif1: 0,
              shif2: 0,
              shif3: 0,
              shif4: 0,
              shif5: 0,
              shif6: 0,
              shif7: 0,
              shif8: 0,
              shif9: 0,
              shif10: 0,
              shif11: 0,
              shif12: 0,
              quantity: 0,
            }]
        }
        dtProduction.push(tempData);
      });

      state.coalGettingDetail.production.pitToRom = calculateShifRitaseAndBcm(dtProduction)

      const payloadList = current(state.payloadLatest);
      state.coalGettingDetail.production.pitToRom.map((row, index) => {
        let counterHeader = 0, shiftRitase, counterHeaderBcm;
        let totalRitase = 0;

        row.subRows.map((sub, idx) => {
          const cloneShift = cloneDeep(sub);
          delete cloneShift['ritase'];
          delete cloneShift['bcm'];
          delete cloneShift['payloadValue'];
          delete cloneShift['fleet'];
          delete cloneShift['rowNumber'];
          delete cloneShift['quantity'];
          delete cloneShift['ewh'];
          delete cloneShift['tph'];
          delete cloneShift['dumpingpoinlocationt'];
          const shift = Object.values(cloneShift);
          const shiftFilterNumber = shift.filter(Number);

          shiftRitase = sum(shiftFilterNumber);
          state.coalGettingDetail.production.pitToRom[index]['subRows'][idx]['ritase'] = shiftRitase;


          payloadList.map((row) => {
            if (row.materialId == sub.material?.id && row.materialName == sub.material?.name && row.equipmentId == sub.unit.id && row.locationName == (state.coalGettingDetail?.pit?.name || state.coalGettingDetail?.pit)) {
              const payloadVal = shiftRitase * row.value;
              state.coalGettingDetail.production.pitToRom[index]['subRows'][idx]['payloadValue'] = payloadVal.toFixed(3);
            }
          })

          if (!state.coalGettingDetail.production.pitToRom[index]['subRows'][idx]['payloadValue']) {
            state.coalGettingDetail.production.pitToRom[index]['subRows'][idx]['bcm'] = 0;
          }
          else {
            state.coalGettingDetail.production.pitToRom[index]['subRows'][idx]['bcm'] = parseFloat(state.coalGettingDetail.production.pitToRom[index]['subRows'][idx]['payloadValue']).toFixed(3);
          }

          totalRitase += shiftRitase ? shiftRitase : 0;


        })

        state.coalGettingDetail.production.pitToRom[index]['ritase'] = totalRitase;

        const shifArr = { shif1: "", shif2: "", shif3: "", shif4: "", shif5: "", shif6: "", shif7: "", shif8: "", shif9: "", shif10: "", shif11: "", shif12: "" };
        Object.keys(shifArr).forEach(key => {
          shifArr[key] = sumBy(state.coalGettingDetail.production.pitToRom[index]['subRows'], key);
        });

        state.coalGettingDetail.production.pitToRom[index] = { ...state.coalGettingDetail.production.pitToRom[index], ...shifArr }

        counterHeaderBcm = sumBy(state.coalGettingDetail.production.pitToRom[index]['subRows'], function (o) { return parseFloat(o.bcm); })
        state.coalGettingDetail.production.pitToRom[index]['bcm'] = parseFloat(counterHeaderBcm).toFixed(3);

        // const tphVal = (parseFloat(counterHeaderBcm)||0)/(parseFloat(row['ewh'])||0);
        // state.coalGettingDetail.production.pitToRom[index]['tph'] = isFinite(tphVal) ? (parseFloat(tphVal).toFixed(3) || 0) : 0;

      });


      // calculation bcm and total material
      state.coalGettingDetail.production.allTotalMaterial = 0;

      if (state.coalGettingDetail?.production?.totalMaterial?.length > 0) {
        state.coalGettingDetail.production.totalMaterial.map((dt, idx) => {
          state.coalGettingDetail.production.totalMaterial[idx] = { ...dt, value: 0 }
        })
      }

      let totalMaterial = state.coalGettingDetail.production.totalMaterial;
      let tableProduction = state.coalGettingDetail.production.pitToRom;
      const tableProductionArr = [];
      tableProduction = tableProduction.map(dt => dt.subRows.map(el => tableProductionArr.push(el)));

      tableProductionArr.map((row, index) => {
        let arr = [];
        let obj = {};
        let newMaterial;
        let isMaterial = false;
        let currentMaterial;

        if (!totalMaterial || totalMaterial.length === 0) {
          obj.materialId = row?.material?.id;
          obj.material = row?.material?.name;
          obj.value = row.bcm ? parseFloat(row.bcm) : 0;
          obj.index = index;
          if (obj.materialId && obj.material) {
            arr.push(obj);
            state.coalGettingDetail.production.totalMaterial = arr;
          }

        } else {
          let rowBcmVal = 0;
          currentMaterial = current(totalMaterial);
          currentMaterial.map((val, indexMaterial) => {
            if (val.materialId == row?.material?.id && val.material == row?.material?.name) {
              if (state.coalGettingDetail.production.totalMaterial[indexMaterial]['value']) {
                if (val.index == index) {
                  rowBcmVal += row.bcm ? parseFloat(row.bcm) : 0;
                  state.coalGettingDetail.production.totalMaterial[indexMaterial]['value'] = rowBcmVal.toFixed(3);
                } else {
                  state.coalGettingDetail.production.totalMaterial[indexMaterial]['value'] = parseFloat(row.bcm) + val.value;
                }
                state.coalGettingDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                state.coalGettingDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
              }
              else {
                state.coalGettingDetail.production.totalMaterial[indexMaterial]['value'] = parseFloat(row.bcm);
                state.coalGettingDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                state.coalGettingDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
              }
            }

            if (!val.material) {
              state.coalGettingDetail.production.totalMaterial[indexMaterial]['value'] = parseFloat(row.bcm);
              state.coalGettingDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
              state.coalGettingDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
              isMaterial = true;
            }
          })

          const currentMaterialAfterUpdate = state.coalGettingDetail.production.totalMaterial;
          newMaterial = currentMaterialAfterUpdate.some(item => item.materialId === row.material?.id)

          if (!isMaterial && !newMaterial && row?.material?.name) {
            obj.materialId = row?.material?.id;
            obj.material = row?.material?.name;
            obj.value = row.bcm ? parseFloat(row.bcm) : 0;
            obj.index = index;
            state.coalGettingDetail.production.totalMaterial.push(obj);
          }
        }
      });

      // Re-check all material exist in row and remaping all
      const arr = [];
      let allTotalValueMaterial = 0;

      tableProductionArr.map((product, index) => {
        let materialObj = {};
        materialObj.value = product.bcm ? parseFloat(product.bcm) : 0;
        materialObj.index = index;
        materialObj.material = product.material?.name;
        materialObj.materialId = product.material?.id;

        if (materialObj.materialId && materialObj.material) {
          arr.push(materialObj);
          allTotalValueMaterial += parseFloat(product.bcm);
        }
      });

      const mergeSameMaterial = Object.values([...arr].reduce((prev, { materialId, material, index, value }) => {
        prev[materialId] = { materialId, material, index, value: parseFloat((prev[materialId] ? parseFloat(prev[materialId].value) : 0) + value).toFixed(3) }
        return prev;
      }, {}));

      state.coalGettingDetail.production.totalMaterial = mergeSameMaterial;
      state.coalGettingDetail.production.allTotalMaterial = parseFloat(allTotalValueMaterial).toFixed(3);

    },

    /**
     * * ADD DATA TABLE PRODUCTION *
     * Todo: to add table on waste removal production page
     * @param action
     */
    addDataTableDropdownProduction: (state, action) => {
      const payload = action.payload;
      const currentChangeRowIndex = payload.subRowIndex + 1;
      const lengthSubRow = state.coalGettingDetail?.production?.pitToRom[payload.tableIndexRow]['subRows'].length - 1;

      if (currentChangeRowIndex != lengthSubRow) {
        state.coalGettingDetail?.production?.pitToRom[payload.tableIndexRow]['subRows'].push(payload.data);
      }
    },

    /**
     * * ONCHANGE PIT *
     * Todo: to change table on coal getting production page
     * @param action
     */
    onChangePit: (state, action) => {
      const payload = action.payload.value;
      if (state.coalGettingDetail.pit !== payload) {
        state.coalGettingDetail.production.allTotalMaterial = 0;
        state.coalGettingDetail.production.pitToRom.map((object, index) => {
          state.coalGettingDetail.production.pitToRom[index] = { ...object, bcm: 0 };

          const listSubRow = [];
          object.subRows.map((sr, idx) => {
            listSubRow.push({ ...sr, loadingPointSubLocation: "", bcm: 0, payloadValue: 0, })
          })

          state.coalGettingDetail.production.pitToRom[index].subRows = listSubRow;
        });

        if (state.coalGettingDetail.production?.totalMaterial?.length > 0) {
          state.coalGettingDetail.production.totalMaterial.map((dt, idx) => {
            state.coalGettingDetail.production.totalMaterial[idx] = { ...dt, value: 0 }
          })
        }

        const payloadList = current(state.payloadLatest);
        state.coalGettingDetail.production.pitToRom.map((object, index) => {
          object.subRows.map((sr, idx) => {
            if (sr.unit?.id && sr.material?.name) {
              payloadList.map((row) => {
                if (row?.materialId == sr.material?.id && row?.materialName == sr.material?.name && row?.equipmentId == sr.unit?.id && row?.locationName == payload) {
                  const ritaseVal = state.coalGettingDetail.production.pitToRom[index]['subRows'][idx]['ritase']
                  const bcmVal = ritaseVal * row.value
                  state.coalGettingDetail.production.pitToRom[index]['subRows'][idx]['bcm'] = bcmVal.toFixed(3);
                  state.coalGettingDetail.production.pitToRom[index]['subRows'][idx]['payloadValue'] = bcmVal.toFixed(3);
                }
              })
            }
          })

          let sumBcmSubRow = sumBy(state.coalGettingDetail.production.pitToRom[index]['subRows'], 'bcm');
          sumBcmSubRow = sumBcmSubRow ? sumBcmSubRow : 0;
          state.coalGettingDetail.production.pitToRom[index]['bcm'] = parseFloat(sumBcmSubRow).toFixed(3);

          // const tphVal = (parseFloat(sumBcmSubRow)||0)/(parseFloat(object['ewh'])||0);
          // state.coalGettingDetail.production.pitToRom[index]['tph'] = isFinite(tphVal) ? (parseFloat(tphVal).toFixed(3) || 0) : 0;
        });

        let totalMaterial = state.coalGettingDetail.production.totalMaterial;
        let tableProduction = current(state.coalGettingDetail.production.pitToRom);
        const tableProductionArr = [];
        tableProduction = tableProduction.map(dt => dt.subRows.map(el => tableProductionArr.push(el)));

        tableProductionArr.map((row, index) => {
          let arr = [];
          let obj = {};
          let newMaterial;
          let isMaterial = false;
          let currentMaterial;

          if (!totalMaterial || totalMaterial.length === 0) {
            obj.materialId = row?.material?.id;
            obj.material = row?.material?.name;
            obj.value = row.bcm ? row.bcm : 0;
            obj.index = index;
            if (obj.materialId && obj.material) {
              arr.push(obj);
              state.coalGettingDetail.production.totalMaterial = arr;
            }

          }
          else {
            let rowBcmVal = 0;
            currentMaterial = current(totalMaterial);
            currentMaterial.map((val, indexMaterial) => {
              if (val.materialId == row?.material?.id && val.material == row?.material?.name) {
                if (state.coalGettingDetail.production.totalMaterial[indexMaterial]['value']) {
                  if (val.index == index) {
                    rowBcmVal += row.bcm ? parseFloat(row.bcm) : 0;
                    state.coalGettingDetail.production.totalMaterial[indexMaterial]['value'] = rowBcmVal.toFixed(3);
                  } else {
                    state.coalGettingDetail.production.totalMaterial[indexMaterial]['value'] = row.bcm + val.value;
                  }
                  state.coalGettingDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                  state.coalGettingDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
                }
                else {
                  state.coalGettingDetail.production.totalMaterial[indexMaterial]['value'] = row.bcm;
                  state.coalGettingDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                  state.coalGettingDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
                }
              }

              if (!val.material) {
                state.coalGettingDetail.production.totalMaterial[indexMaterial]['value'] = row.bcm;
                state.coalGettingDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                state.coalGettingDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
                isMaterial = true;
              }
            })

            const currentMaterialAfterUpdate = state.coalGettingDetail.production.totalMaterial;
            newMaterial = currentMaterialAfterUpdate.some(item => item.materialId === row.material?.id)

            if (!isMaterial && !newMaterial && row?.material?.name) {
              obj.materialId = row?.material?.id;
              obj.material = row?.material?.name;
              obj.value = row.bcm ? row.bcm : 0;
              obj.index = index;
              state.coalGettingDetail.production.totalMaterial.push(obj);
            }
          }
        });

        // Re-check all material exist in row and remaping all
        const productionData = current(state.coalGettingDetail.production.pitToRom);
        const arr = [];
        let allTotalValueMaterial = 0;

        tableProductionArr.map((product, index) => {
          let materialObj = {};
          materialObj.value = product.bcm ? parseFloat(product.bcm) : 0;
          materialObj.index = index;
          materialObj.material = product.material?.name;
          materialObj.materialId = product.material?.id;

          if (materialObj.materialId && materialObj.material) {
            arr.push(materialObj);
            allTotalValueMaterial += parseFloat(product.bcm);
          }
        });

        const mergeSameMaterial = Object.values([...arr].reduce((prev, { materialId, material, index, value }) => {
          prev[materialId] = { materialId, material, index, value: parseFloat((prev[materialId] ? parseFloat(prev[materialId].value) : 0) + value).toFixed(3) }
          return prev;
        }, {}));

        state.coalGettingDetail.production.totalMaterial = mergeSameMaterial;
        state.coalGettingDetail.production.allTotalMaterial = parseFloat(allTotalValueMaterial).toFixed(3);;

      }
    },

    onChangeTotalPitToPort: (state, action) => {
      const value = action.payload;
      //console.log('😒 | file: reducer.js:965 | value:', value);
      state.coalGettingDetail.production.pitToPort.total = value;
    },

    /**
     * * ONCHANGE TABLE PRODUCTION *
     * Todo: to change table on waste removal production page
     * @param action
     */
    onChangeTableProduction: (state, action) => {
      const payload = action.payload;
      let checkValue = payload.value,
        counterHeader = 0,
        counterHeaderRitase,
        counterHeaderBcm,
        shiftRitase;

      // console.log("payload", payload)
      // console.log("state.payloadOlder", current(state.payloadOlder))
      // console.log("state.coalGettingDetail.production.pitToRom", current(state.coalGettingDetail.production))
      if (isInteger(parseInt(payload.value)) && payload.tableRowName !== "notes" && payload.tableRowName !== "ewh") checkValue = parseInt(payload.value);
      /**
       * Todo: to parsing new value into row column
       */
      if (payload.canExpand) {
        state.coalGettingDetail.production.pitToRom
        [payload.tableIndexRow]
        [payload.tableRowName] = checkValue;

        if (payload.tableRowName === "unit") {
          state.coalGettingDetail.production.pitToRom
          [payload.tableIndexRow]
          ["id"] = "";
        }
      }
      if (!payload.canExpand) {
        state.coalGettingDetail.production.pitToRom
        [payload.tableIndexRow]
        ['subRows']
        [payload.subRowIndex]
        [payload.tableRowName] = checkValue;


        state.coalGettingDetail?.production?.pitToRom[payload.tableIndexRow]['subRows'].push({});
        state.coalGettingDetail?.production?.pitToRom[payload.tableIndexRow]['subRows'].pop();

        if (payload.tableRowName === "unit") {
          state.coalGettingDetail.production.pitToRom
          [payload.tableIndexRow]
          ['subRows']
          [payload.subRowIndex]
          ["id"] = "";

          state.coalGettingDetail.production.pitToRom
          [payload.tableIndexRow]
          ['subRows']
          [payload.subRowIndex]
          ['loadingPoint'] = state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['loadingPoint'];
        }
        else if (payload.tableRowName === "loadingPoint") {
          state.coalGettingDetail.production.pitToRom
          [payload.tableIndexRow]
          ['subRows']
          [payload.subRowIndex]
          ["dumpingPoint"] = "";
        }

        if (payload.tableRowName === "loadingPointSubLocation") {
          const dataSubrow = current(state.coalGettingDetail.production.pitToRom
          [payload.tableIndexRow]
          ['subRows']);

          dataSubrow.map((dt, idx) => {
            state.coalGettingDetail.production.pitToRom
            [payload.tableIndexRow]
            ['subRows']
            [idx]
            ["loadingPointSubLocation"] = checkValue;
          })
        }
      }

      const currentMaterial = state.coalGettingDetail.production.pitToRom
      [payload.tableIndexRow]
      ['subRows']?.[payload.subRowIndex]?.['material'];

      const currentTableIndexRow = payload.tableIndexRow;
      const currentSubRowIndex = payload.subRowIndex;

      const currentUnit = state.coalGettingDetail.production.pitToRom
      [payload.tableIndexRow]
      ['subRows']?.[payload.subRowIndex]?.['unit'];

      const currentSubRows = current(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows']);
      const equipmentId = currentUnit?.id;
      const materialName = currentMaterial?.name
      const materialId = currentMaterial?.id;

      const payloadList = current(state.payloadLatest);
      let tableProduction = current(state.coalGettingDetail.production.pitToRom);
      //if chosen unit or material
      let counterQuantity = 0
      if ((payload.tableRowName === "unit" || payload.tableRowName === "material") && !payload.canExpand) {


        tableProduction.map((row, index) => {
          row.subRows.map((sub, idx) => {
            if (isInteger(parseInt(sub["quantity"])) && index === payload.tableIndexRow && sub["unit"]) {
              counterHeader += parseInt(sub["quantity"]);

            }
          })
        })

        state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]["quantity"] = counterHeader;

        const quantityExists = state.coalGettingDetail.production.pitToRom?.[payload.tableIndexRow]?.["subRows"]?.[payload.subRowIndex]?.hasOwnProperty("quantity");

        if (quantityExists) {
          const currentQuantity =
            state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]["subRows"]?.[payload.subRowIndex]?.["quantity"];
          if (!currentQuantity) {

            state.coalGettingDetail.production.pitToRom[payload.tableIndexRow][
              "subRows"
            ][payload.subRowIndex]["quantity"] = 1
          }
        }
        //remove list from payloadOlder to use new payload
        // const currentOlderList = state.payloadOlder.filter(
        //   (item) =>
        //     item.tableIndexRow !== currentTableIndexRow &&
        //     item.subRow !== currentSubRowIndex
        // );

        // console.log("currentOlderList", currentOlderList)
        // if (currentOlderList) state.payloadOlder = currentOlderList;

        if (!payload.canExpand && payload.data) {
          //remove list from payloadOlder to use new payload
          const currentOlderList = state.payloadOlder.filter(
            (item) =>
              // item.tableIndexRow !== currentTableIndexRow &&
              // item.subRow !== currentSubRowIndex &&
              item.idSubRow !== payload.data.subRowKey
          );
          state.payloadOlder = currentOlderList;
          // if (currentOlderList) state.payloadOlder = currentOlderList;
          // console.log("updatedData currentOlderList", JSON.stringify(currentOlderList));
        }
        if (currentUnit && currentMaterial) {
          // console.log("currentUnit", currentUnit)
          // const isFleetInArray = state.payloadOlder.find(
          //   (item) =>
          //     item.tableIndexRow === payload.tableIndexRow &&
          //     item.subRow === payload.subRowIndex && !isNaN(Number(item.value)) && !isNaN(Number(item.payload)));
          // const payloadList =
          //   isFleetInArray !== undefined
          //     ? current(state.payloadOlder)
          //     // ? current(isFleetInArray)
          //     : current(state.payloadLatest);
          // const payLatest = current(state.payloadLatest)


          state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['bcm'] = 0;

          if (!payload.canExpand) {

            state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = 0;
            payloadList.map((row) => {
              if (row.materialId == materialId && row.materialName == materialName && row.equipmentId == equipmentId && row.locationName == payload.locPit) {
                const ritaseVal = state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['ritase']
                const currentQuantity =
                  state.coalGettingDetail.production.pitToRom[
                  payload.tableIndexRow
                  ]["subRows"][payload.subRowIndex]["quantity"];
                const bcmVal = ritaseVal * row.value * currentQuantity
                state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = bcmVal.toFixed(3);
                //const b = state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = bcmVal.toFixed(3);

                state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['payloadValue'] = bcmVal.toFixed(3)
                // console.log("ritaseValritaseVal", ritaseVal)

                // console.log("bcmVal", bcmVal)
                // console.log("b", b)

              }
            })

            // payloadList.map((row) => {
            //   if (isFleetInArray !== undefined) {
            //     if (row.materialId == materialId && row.materialName == materialName && row.equipmentId == equipmentId && row.locationName == payload.locPit && row.tableIndexRow == payload.tableIndexRow && row.subRow == payload.subRowIndex) {
            //       // console.log("row", row)
            //       const ritaseVal = state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['ritase']
            //       const currentQuantity =
            //         state.coalGettingDetail.production.pitToRom[
            //         payload.tableIndexRow
            //         ]["subRows"][payload.subRowIndex]["quantity"];
            //       const bcmVal = ritaseVal * row.value * currentQuantity
            //       state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = bcmVal.toFixed(3);
            //       state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['payloadValue'] = formatNumber(bcmVal);
            //     }
            //   } else {
            //     //console.log("row with no fleet in array", row)

            //     if (row.materialId == materialId && row.materialName == materialName && row.equipmentId == equipmentId && row.locationName == payload.locPit) {

            //       const ritaseVal = state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['ritase']
            //       const currentQuantity =
            //         state.coalGettingDetail.production.pitToRom[
            //         payload.tableIndexRow
            //         ]["subRows"][payload.subRowIndex]["quantity"];
            //       const bcmVal = ritaseVal * row.value * currentQuantity
            //       state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = bcmVal.toFixed(3);
            //       state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['payloadValue'] = formatNumber(bcmVal);
            //     }
            //   }
            // })

            let rsCounterHeaderBcm = sumBy(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'], function (o) { return parseFloat(o.bcm); })
            rsCounterHeaderBcm = rsCounterHeaderBcm ? rsCounterHeaderBcm : 0;
            state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['bcm'] = parseFloat(rsCounterHeaderBcm).toFixed(3)

            //   console.log("parseFloat(rsCounterHeaderBcm).toFixed(3)", parseFloat(rsCounterHeaderBcm).toFixed(3))
          }
        }
      }

      let totalMaterial = state.coalGettingDetail.production.totalMaterial;
      // console.log("totalMat", JSON.stringify(totalMaterial))
      if (isInteger(checkValue)
        // && equipmentId
        && payload.tableRowName !== "notes"
        // && payload.tableRowName !== "quantity"
        && payload.tableRowName !== "ewh") {
        //console.log("typing ...");
        //console.log("materialName", materialName)

        tableProduction.map((row, index) => {
          row.subRows.map((sub, idx) => {
            if (sub[payload.tableRowName] && isInteger(parseInt(sub[payload.tableRowName])) && index === payload.tableIndexRow && sub['unit']) {

              counterHeader += parseInt(sub[payload.tableRowName]);
            }
            if (idx === payload.subRowIndex && index === payload.tableIndexRow) {
              const cloneShift = cloneDeep(sub);
              delete cloneShift['ritase'];
              delete cloneShift['bcm'];
              delete cloneShift['payloadValue'];
              delete cloneShift['fleet'];
              delete cloneShift['rowNumber'];
              delete cloneShift['quantity'];
              delete cloneShift['ewh'];
              delete cloneShift['tph'];
              delete cloneShift["sortId"]; // Remove the sortId key

              const shift = Object.values(cloneShift);
              const shiftFilterNumber = shift.filter(value => typeof value === "number" && !isNaN(value));

              shiftRitase = sum(shiftFilterNumber);
              // console.log("cloneShift", cloneShift)
              // console.log("shiftRitase", shiftRitase)
            }
          })
        });
        //  console.log("counterHeader", counterHeader)

        const currentMaterial = state.coalGettingDetail.production.pitToRom
        [payload.tableIndexRow]
        ['subRows']
        [payload.subRowIndex]
        ['material'];

        const currentUnit = state.coalGettingDetail.production.pitToRom
        [payload.tableIndexRow]
        ['subRows']
        [payload.subRowIndex]
        ['unit'];

        const currentQuantity =
          state.coalGettingDetail.production.pitToRom[payload.tableIndexRow][
          "subRows"
          ][payload.subRowIndex]["quantity"];

        if (currentMaterial) {
          //    console.log(" current material", currentMaterial);
          const equipmentId = currentUnit?.id;
          const materialName = current(currentMaterial).name;
          const materialId = current(currentMaterial).id;
          //const payloadList = current(state.payloadLatest);


          //chek fleet current input inside paylod older if exist use older payload
          // const isFleetInArray = state.payloadOlder.find(
          //   (item) =>
          //     item.tableIndexRow === payload.tableIndexRow &&
          //     item.subRow === payload.subRowIndex && !isNaN(Number(item.value)) && !isNaN(Number(item.payload)));
          //   console.log("isFleetInArray", JSON.stringify(isFleetInArray))
          let isFleetInArray;
          if (!payload.canExpand) {
            isFleetInArray = state.payloadOlder.filter(
              (item) =>
                // Uncomment and include additional conditions if needed
                item.tableIndexRow === payload.tableIndexRow &&
                item.subRow === payload.subRowIndex &&
                item.idSubRow === payload.data?.subRowKey &&
                !isNaN((item.value)) && Number(item.value) > 0
            );
          }
          //  console.log("isFleetInArray", JSON.stringify(isFleetInArray))
          const payloadList =
            Array.isArray(isFleetInArray) && isFleetInArray.length > 0
              ? current(state.payloadOlder)

              : current(state.payloadLatest);

          // console.log("payloadList", payloadList)
          if (payload.locPit && payload.locPit !== '') {
            if (Array.isArray(isFleetInArray) && isFleetInArray.length > 0) {
              payloadList.map((row) => {
                if (row.materialId == materialId && row.materialName == materialName && row.equipmentId == equipmentId && row.locationName == payload.locPit && row.idSubRow == payload.data?.subRowKey) {
                  console.log("row", row)
                  const currentQuantity =
                    state.coalGettingDetail.production.pitToRom[
                    payload.tableIndexRow
                    ]["subRows"][payload.subRowIndex]["quantity"];

                  const payloadVal = shiftRitase * row.value * currentQuantity;
                  state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = (payloadVal).toFixed(3)
                  //  const ba = state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = (payloadVal).toFixed(3)

                  state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['payloadValue'] = payloadVal.toFixed(3)
                }
              })
            }
            else {
              payloadList.map((row) => {
                if (row.materialId == materialId && row.materialName == materialName && row.equipmentId == equipmentId && row.locationName == payload.locPit) {
                  console.log("row new payload", row)
                  const currentQuantity =
                    state.coalGettingDetail.production.pitToRom[
                    payload.tableIndexRow
                    ]["subRows"][payload.subRowIndex]["quantity"];

                  const payloadVal = shiftRitase * row.value * currentQuantity;
                  state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = (payloadVal).toFixed(3)
                  state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['payloadValue'] = payloadVal.toFixed(3)
                }
              })
            }
          }
        }
        else {
          //    console.log("no current material");
        }

        if (!currentQuantity) {

          state.coalGettingDetail.production.pitToRom[payload.tableIndexRow][
            "subRows"
          ][payload.subRowIndex]["quantity"] = 1
        }

        state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['ritase'] = shiftRitase;

        if (!state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['payloadValue']) {
          state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = 0;
        }
        else {
          // console.log("", JSON.stringify(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['payloadValue']))
          state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = parseFloat(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'][payload.subRowIndex]['payloadValue']).toFixed(3);
        }

        //fill current cell
        state.coalGettingDetail.production.pitToRom[payload.tableIndexRow][payload.tableRowName] = counterHeader;
        const currentSubRow = current(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows']);
        let totalRitase = 0;
        currentSubRow.map((val) => {
          totalRitase += val.ritase ? val.ritase : 0;
        })
        // console.log('😒 | file: reducer.js:1148 | totalRitase:', totalRitase);
        state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['ritase'] = totalRitase;
        //console.log("totalRitase", totalRitase)

        counterHeaderBcm = sumBy(current(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows']), function (o) { return parseFloat(o.bcm); })
        state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['bcm'] = parseFloat(counterHeaderBcm).toFixed(3)

      }


      tableProduction = current(state.coalGettingDetail.production.pitToRom);
      // console.log("tableProduction", tableProduction)
      const tableProductionArr = [];
      tableProduction = tableProduction.map(dt => dt.subRows.map(el => tableProductionArr.push(el)));

      tableProductionArr.map((row, index) => {
        let arr = [];
        let obj = {};
        let newMaterial;
        let isMaterial = false;
        let currentMaterial;

        if (!totalMaterial || totalMaterial.length == 0) {
          //  console.log("totalMaterial currentMaterial", totalMaterial)
          obj.materialId = row?.material?.id;
          obj.material = row?.material?.name;
          obj.value = row.bcm ? parseFloat(row.bcm) : 0;
          obj.index = index;
          if (obj.materialId && obj.material) {
            arr.push(obj);
            state.coalGettingDetail.production.totalMaterial = arr;
          }

        }
        else {
          let rowBcmVal = 0;
          currentMaterial = current(totalMaterial);

          currentMaterial.map((val, indexMaterial) => {
            if (val.materialId == row?.material?.id && val.material == row?.material?.name) {
              if (state.coalGettingDetail.production.totalMaterial[indexMaterial]['value']) {
                if (val.index == index) {
                  rowBcmVal += row.bcm ? parseFloat(row.bcm) : 0;
                  state.coalGettingDetail.production.totalMaterial[indexMaterial]['value'] = rowBcmVal;
                } else {
                  state.coalGettingDetail.production.totalMaterial[indexMaterial]['value'] = parseFloat(row.bcm) + val.value;
                }
                state.coalGettingDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                state.coalGettingDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
              }
              else {
                state.coalGettingDetail.production.totalMaterial[indexMaterial]['value'] = parseFloat(row.bcm);
                state.coalGettingDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                state.coalGettingDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
              }
            }

            if (!val.material) {
              state.coalGettingDetail.production.totalMaterial[indexMaterial]['value'] = parseFloat(row.bcm);
              state.coalGettingDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
              state.coalGettingDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
              isMaterial = true;
            }
          })

          const currentMaterialAfterUpdate = state.coalGettingDetail.production.totalMaterial;
          newMaterial = currentMaterialAfterUpdate.some(item => item.materialId === row.material?.id)

          if (!isMaterial && !newMaterial && row?.material?.name) {
            obj.materialId = row?.material?.id;
            obj.material = row?.material?.name;
            obj.value = row.bcm ? parseFloat(row.bcm) : 0;
            obj.index = index;
            state.coalGettingDetail.production.totalMaterial.push(obj);
          }
        }
      })

      // Re-check all material exist in row and remaping all
      const productionData = current(state.coalGettingDetail.production.pitToRom);
      const arr = [];
      let allTotalValueMaterial = 0;

      tableProductionArr.map((product, index) => {
        //  console.log("prod", product)
        let materialObj = {};
        materialObj.value = product.bcm ? parseFloat(product.bcm) : 0;
        materialObj.index = index;
        materialObj.material = product.material?.name;
        materialObj.materialId = product.material?.id;

        if (materialObj.materialId && materialObj.material) {
          arr.push(materialObj);
          allTotalValueMaterial += parseFloat(product.bcm);
        }
      });

      const mergeSameMaterial = Object.values([...arr].reduce((prev, { materialId, material, index, value }) => {
        prev[materialId] = { materialId, material, index, value: parseFloat((prev[materialId] ? parseFloat(prev[materialId]?.value) : 0) + value).toFixed(3) }
        return prev;
      }, {}));

      state.coalGettingDetail.production.totalMaterial = mergeSameMaterial;
      state.coalGettingDetail.production.allTotalMaterial = parseFloat(allTotalValueMaterial).toFixed(3);
      // console.log("mergeSameMaterial", mergeSameMaterial)
      //console.log("allTotalValueMaterial", allTotalValueMaterial)

      const dataPitToRom = current(state.coalGettingDetail.production.pitToRom);
      state.coalGettingDetail.production.pitToRom = calculateShifRitaseAndBcm(dataPitToRom)

      // const tphVal = (parseFloat(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['bcm'])||0) /
      //   (parseFloat(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['ewh']?.toString())||0);
      // state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['tph'] = isFinite(tphVal) ? (parseFloat(tphVal).toFixed(3) || 0) : 0;
    },

    /**
     * * ON DELETE ROW TABLE PRODUCTION *
     * Todo: to delete page waste removal detail production table
     * @param action
     */
    onDeleteRowTableProduction: (state, action) => {
      // console.log("ac", action)
      let renewFleet = 0;
      let renewProdRn = 0;
      let renewNonProdRn = 0;
      let currentMaterial;
      let totalMaterialData = state.coalGettingDetail.production.totalMaterial;
      const payload = action.payload;
      let currentOlderList = []
      if (payload.canExpand) {
        // console.log("payload.data", payload.data)
        currentOlderList = state.payloadOlder.filter(item =>
          !payload.data.subRows.some(subRow => item.idSubRow === subRow.subRowKey)
        );
        state.payloadOlder = currentOlderList;

        const dataRow = current(state.coalGettingDetail.production.pitToRom);
        currentMaterial = current(totalMaterialData);
        const deletedRowIdx = dataRow.findIndex(x => x.fleet === payload.fleet && x.status?.job === payload.status?.job);
        state.coalGettingDetail.production.totalMaterial.map((val, idxMaterial) => {
          state.coalGettingDetail.production.pitToRom[deletedRowIdx]['subRows'].map((dt, idx) => {
            if (val.materialId === dt.material.id && val.material === dt.material.name) {
              if (payload.rowBcm) {
                const currBcm = parseFloat(dt.bcm);
                const materialVal = val.value - currBcm
                state.coalGettingDetail.production.totalMaterial[idxMaterial]['value'] = materialVal.toFixed(3);
                const totalMaterialVal = sumBy(state.coalGettingDetail.production.totalMaterial, 'value')
                state.coalGettingDetail.production.allTotalMaterial = parseFloat(totalMaterialVal).toFixed(3);
              }
            }
          })
        })

        state.coalGettingDetail.production.totalMaterial = state.coalGettingDetail.production.totalMaterial.filter((x, index) => parseInt(x.value) !== 0);

        const deletedRow = dataRow.findIndex(x => x.fleet === payload.fleet && x.status?.job === payload.status?.job);
        state.coalGettingDetail.production.pitToRom = dataRow.filter((x, index) => index !== deletedRow);

        state.coalGettingDetail.production.pitToRom.map((object, index) => {
          renewFleet += 1;
          if (object.status?.job === "Production") renewProdRn += 1;
          if (object.status?.job === "Non Production") renewNonProdRn += 1;
          state.coalGettingDetail.production.pitToRom[index] = { ...object, fleet: renewFleet, rowNumber: object.status?.job === "Production" ? renewProdRn : renewNonProdRn };

          const listSubRow = [];
          object.subRows.map((sr, idx) => {
            listSubRow.push({ ...sr, fleet: renewFleet, rowNumber: object.status?.job === "Production" ? renewProdRn : renewNonProdRn })
          })

          state.coalGettingDetail.production.pitToRom[index].subRows = listSubRow;
        });
      } else {

        //remove quantity
        let counterHeaderQuantity = state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]["quantity"]
        let currentQuantity = state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]["subRows"]?.[payload.subRowIndex]?.["quantity"];

        counterHeaderQuantity -= parseInt(currentQuantity);

        state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]["quantity"] = counterHeaderQuantity;

        //remove payloadOlder
        // if (!payload.canExpand) {
        currentOlderList = state.payloadOlder.filter(
          (item) =>
            item.idSubRow !== payload.data.subRowKey
        );
        // console.log("currentOlderList", JSON.stringify(currentOlderList))
        state.payloadOlder = currentOlderList;
        //  }

        const parentRowBcmBeforeDelete = state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['bcm'];
        const dataSubRow = current(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows']);
        // const dataPayloadOlder = current(state.payloadOlder);
        // const filtDataPa = dataPayloadOlder.filter((item) => item.tableIndexRow === payload.tableIndexRow)

        if (dataSubRow.length > 1) {
          state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'] = dataSubRow.filter((x, index) => index != payload.subRowIndex);
        } else {
          const arr = [];
          const obj = { ...dataSubRow[0] };
          arr.push(obj);
          state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'] = arr;
        }

        currentMaterial = current(totalMaterialData);
        currentMaterial.map((val, idxMaterial) => {
          if (val.materialId == payload.rowMaterialId && val.material == payload.rowMaterial) {
            if (payload.rowBcm) {
              const sumSubRowBcm = sumBy(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'], function (o) { return parseFloat(o.bcm); });
              state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['bcm'] = parseFloat(sumSubRowBcm).toFixed(3);
              const rs1 = (parseFloat(val.value) - parseFloat(parentRowBcmBeforeDelete)).toFixed(3)
              const rs2 = (parseFloat(rs1) + parseFloat(sumSubRowBcm)).toFixed(3)
              state.coalGettingDetail.production.totalMaterial[idxMaterial]['value'] = (payload.rowRitase) ? parseFloat(rs2) : val.value - payload.rowBcm;
              const totalMaterialVal = sumBy(current(state.coalGettingDetail.production.totalMaterial), 'value');
              state.coalGettingDetail.production.allTotalMaterial = parseFloat(totalMaterialVal).toFixed(3);
            }
          }
        })

        const parentRow = state.coalGettingDetail.production.pitToRom[payload.tableIndexRow];

        // if (payload.rowRitase != 0) {
        const shifArr = { shif1: "", shif2: "", shif3: "", shif4: "", shif5: "", shif6: "", shif7: "", shif8: "", shif9: "", shif10: "", shif11: "", shif12: "" };
        Object.keys(shifArr).forEach(key => {
          shifArr[key] = sumBy(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'], key);
        });

        const currentRitaseParentRow = sumBy(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['subRows'], 'ritase');
        state.coalGettingDetail.production.pitToRom[payload.tableIndexRow] = { ...parentRow, ...shifArr, ritase: currentRitaseParentRow }
        // }

        // const tphVal = (parseFloat(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['bcm'])||0) /
        //   (parseFloat(state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['ewh'])||0);
        // state.coalGettingDetail.production.pitToRom[payload.tableIndexRow]['tph'] = isFinite(tphVal) ? (parseFloat(tphVal).toFixed(3) || 0) : 0;
      }
      // console.log("payload older", JSON.stringify(state.payloadOlder))


    },

    mappingMaterialDetailPage: (state) => {
      const productionData = current(state.coalGettingDetail.production?.pitToRom);

      if (productionData.length != undefined) {
        const arr = [];
        let allTotalValueMaterial = 0;

        productionData.map((product, index) => {
          product.subRows.map((sr, idx) => {
            let materialObj = {};
            materialObj.value = sr.bcm ? parseFloat(sr.bcm) : 0;
            materialObj.index = idx;
            materialObj.material = sr.material?.name;
            materialObj.materialId = sr.material?.id;

            arr.push(materialObj);
            allTotalValueMaterial += parseFloat(sr.bcm);
          })
        });

        const mergeSameMaterial = Object.values([...arr].reduce((prev, { materialId, material, index, value }) => {
          prev[materialId] = { materialId, material, index, value: parseFloat((prev[materialId] ? parseFloat(prev[materialId].value) : 0) + value).toFixed(3) }
          return prev;
        }, {}));

        state.coalGettingDetail.production.totalMaterial = mergeSameMaterial;
        state.coalGettingDetail.production.allTotalMaterial = parseFloat(allTotalValueMaterial).toFixed(3);
      }
    },

    /**
     * * ADD DATA TABLE MAINTENANCE *
     * Todo: to add table on waste removal maintenance page
     * @param action
     */
    addDataTableMaintenance: (state, action) => {
      state.coalGettingDetail.maintenanceUnit.maintenanceDataList.push(action.payload);
    },

    /**
     * * ONCHANGE TABLE MAINTENANCE *
     * Todo: to add table on waste removal maintenance page
     * @param action
     */
    onChangeTableMaintenance: (state, action) => {
      const payload = action.payload;
      state.coalGettingDetail.maintenanceUnit.maintenanceDataList[payload.tableIndexRow][payload.tableRowName] = payload.value;
    },

    /**
     * * ON DELETE ROW TABLE SLIPPERY *
     * Todo: to delete page waste removal detail slippery table
     * @param action
     */
    onDeleteRowTableMaintenance: (state, action) => {
      const payload = action.payload;
      state.coalGettingDetail.maintenanceUnit.maintenanceDataList = state.coalGettingDetail.maintenanceUnit.maintenanceDataList.filter((x, index) => index != payload.tableIndexRow);
    },

    /**
     * * ON ADD TABLE SLIPPERY *
     * Todo: to add empty value for editing
     * @param action
     */
    onAddTableSlippery: (state, action) => {
      const payload = action.payload;
      state.coalGettingDetail.rainSlippery["actualTotalRainSlipperyList"].push(payload);
    },

    /**
     * * ON CHANGE TABLE DATE SLIPPERY *
     * Todo: to change page waste removal detail slippery table date
     * @param action
     */
    onChangeTableDateSlippery: (state, action) => {
      const payload = action.payload;
      state.coalGettingDetail.rainSlippery[payload.tableName][payload.tableIndexRow][payload.tableRowName] = payload.value === 'null' ? "" : moment(payload.value).format();
      if (payload.tableRowName === "endRain") {
        state.coalGettingDetail.rainSlippery[payload.tableName][payload.tableIndexRow]["startSlippery"] = payload.startSlippery !== null ? moment(payload.startSlippery).format() : "";
      }
      if (payload.endSlippery) {
        let rainSlippery = current(state.coalGettingDetail.rainSlippery[payload.tableName][payload.tableIndexRow])
        if (rainSlippery.startSlippery && rainSlippery.endSlippery) {
          const start = moment(rainSlippery.startSlippery)
          const end = moment(rainSlippery.endSlippery)
          let durationSlippery = moment(end).diff(start, "minutes") / 60
          durationSlippery = parseFloat(durationSlippery.toFixed(2))
          state.coalGettingDetail.rainSlippery[payload.tableName][payload.tableIndexRow]['durationSlippery'] = durationSlippery;
        } else {
          state.coalGettingDetail.rainSlippery[payload.tableName][payload.tableIndexRow]['durationSlippery'] = 0;
        }
      }
      else {
        let rainSlippery = current(state.coalGettingDetail.rainSlippery[payload.tableName][payload.tableIndexRow])
        if (rainSlippery.startRain && rainSlippery.endRain) {
          const start = moment(rainSlippery.startRain)
          const end = moment(rainSlippery.endRain)
          let durationRain = moment(end).diff(start, "minutes") / 60
          durationRain = parseFloat(durationRain.toFixed(2))
          state.coalGettingDetail.rainSlippery[payload.tableName][payload.tableIndexRow]['durationRain'] = durationRain;
        } else {
          state.coalGettingDetail.rainSlippery[payload.tableName][payload.tableIndexRow]['durationRain'] = 0;
        }
      }

      if (payload.tableRowName === "endRain" && current(state.coalGettingDetail.rainSlippery[payload.tableName][payload.tableIndexRow]).endSlippery !== "") {
        let rainSlippery = current(state.coalGettingDetail.rainSlippery[payload.tableName][payload.tableIndexRow])
        const start = moment(rainSlippery.startSlippery)
        const end = moment(rainSlippery.endSlippery)
        const durationSlippery = moment(end).diff(start, "minutes") / 60
        state.coalGettingDetail.rainSlippery[payload.tableName][payload.tableIndexRow]['durationSlippery'] = durationSlippery.toFixed(2).replace(".", ",");
      }
    },

    /**
     * * ON DELETE ROW TABLE SLIPPERY *
     * Todo: to delete page waste removal detail slippery table
     * @param action
     */
    onDeleteRowTableSlippery: (state, action) => {
      const payload = action.payload;
      state.coalGettingDetail.rainSlippery[payload.tableName] = state.coalGettingDetail.rainSlippery[payload.tableName].filter((x, index) => index != payload.tableIndexRow);
    },

    /**
     * * ON CHANGE TABLE DATE SLIPPERY CH*
     * Todo: to change page waste removal detail slippery table date INPUT CH
     * @param action
     */
    onChangeTableDateSlipperyCH: (state, action) => {
      const payload = action.payload;

      state.coalGettingDetail.rainSlippery[payload.tableName][payload.tableIndexRow][payload.tableRowName] = payload.value;
    },

    /**
     * * ON CHANGE TABLE DATE SLIPPERY CH*
     * Todo: to change page waste removal detail slippery table date INPUT CH
     * @param action
     */
    onChangePitWatch: (state, action) => {
      const payload = action.payload;
      state.coalGettingDetail[payload.name] = payload.value.value;
    },

    onChangeReportDate: (state, action) => {
      const payload = action.payload;
      state.coalGettingDetail['reportDate'] = payload.value;
    },

    onChangeDocumentCoal: (state, action) => {
      const payload = action.payload;
      // state.documentList.push(payload);
      state.documentList[0] = (payload);
    },

    onResetDocument: (state, action) => {
      state.documentList = [];
    },

    onResetCoalDetail: (state) => {
      state.coalGettingDetail = {
        rainSlippery: {
          id: null,
          forecastTotalRainSlipperyList: [],
          actualTotalRainSlipperyList: [],
        },
        production: {
          id: null,
          pitToRom: [],
          pitToPort: {
            total: 0,
          },
          documentList: [],
          totalMaterial: [],
          allTotalMaterial: 0,
          getLatest: false,
        },
        maintenanceUnit: {
          id: null,
          maintenanceDataList: []
        },
        userName: "",
        pit: "",
        shift: null,
        reportDate: null,
      }

      state.getDetailData = false;

    },

    onResetCoalBCM: (state) => {
      state.bcmSummary = {
        loading: false,
        records: []
      };

      state.bcmSummaryDetailBar = {
        loading: false,
        records: []
      };
    },

    onResetCoalBCMDetail: (state) => {
      state.bcmSummaryDetail = {
        loading: false,
        records: []
      };
    },

    addOlderPayloadList: (state, action) => {

      const payload = action.payload;
      const payList = [];
      state.coalGettingDetail.production.pitToRom.map((item, index) => {
        //    if (index !== 0) {

        item.subRows.map((row, rowIndex) => {
          //  if (row.unit) {
          payList.push({
            tableIndexRow: index,
            subRow: rowIndex,
            fleet: item.fleet,
            materialId: row.material?.id ? row.material?.id : "",
            materialName: row.material?.name ? row.material?.name : "",
            equipmentId: row.unit?.id ? row.unit?.id : "",
            locationName: payload.locPit,
            quantity: row.quantity,
            ritase: row.ritase,
            // pay: (row?.payloadValue),
            payload: parseFloat(row?.payloadValue),
            value: row.payloadValue !== undefined && row.payloadValue > 0 && !isNaN(row.payloadValue) ? (parseFloat((parseFloat(row?.payloadValue)) / row.quantity / row.ritase)) : 0,
            idx: item.id,
            idSubRow: index + "_" + rowIndex
          });
          //  }
        });

        state.payloadOlder = payList;
        // console.log("payList older", payList)
        //   }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCoalGetting.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCoalGetting.fulfilled, (state, action) => {
      state.loading = false;
      state.coalGetting.totalRecords = action.payload.recordsTotal;
      state.coalGetting.records = action.payload.list;
    });
    builder.addCase(getCoalGetting.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(getVersionList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getVersionList.fulfilled, (state, action) => {
      state.versionList = action.payload;
    });
    builder.addCase(getVersionList.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(getLatestPayload.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLatestPayload.fulfilled, (state, action) => {
      const fData = action?.payload.filter(el => el.type === "Coal")
      state.payloadLatest = fData;
    });
    builder.addCase(getLatestPayload.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(saveCoalGetting.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(saveCoalGetting.fulfilled, (state, action) => {
      state.response = action.payload;
    });
    builder.addCase(saveCoalGetting.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(submitCoalGetting.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(submitCoalGetting.fulfilled, (state, action) => {
      state.response = action.payload;
    });
    builder.addCase(submitCoalGetting.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(addCoalGetting.pending, (state, action) => {
      state.coalGettingCreate.loading = true;
    });
    builder.addCase(addCoalGetting.fulfilled, (state, action) => {
      state.coalGettingCreate.records = action.payload;
    });
    builder.addCase(addCoalGetting.rejected, (state, action) => {
      state.coalGettingCreate.loading = false;
      state.coalGettingCreate.error = 'Invalid add data';
    });

    builder.addCase(deleteCoalGetting.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteCoalGetting.fulfilled, (state, action) => {
      state.response = action.payload;
    });
    builder.addCase(deleteCoalGetting.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid add data';
    });

    builder.addCase(getDetailCoalGetting.pending, (state, action) => {
      state.loading = true;
    });
    // builder.addCase(getDetailCoalGetting.fulfilled, (state, action) => {

    //   //state.coalGettingDetail = action.payload;
    //   // console.log("action.payload", action.payload)
    //   // return false
    //   const data = action.payload;
    //   const updatedPitToRom = data.production.pitToRom.map((item, index) => ({
    //     ...item,
    //     subRows: item.subRows.map((subrow, rowIndex) => ({
    //       ...subrow,
    //       subRowKey: index + "_" + rowIndex
    //     })

    //     )
    //     //subRowKey: 124, // Add the subRowKey property with value 124
    //   }));
    //   data.pitToRom = updatedPitToRom
    //   console.log("action.payload", data)
    //   if (updatedPitToRom) {
    //     state.coalGettingDetail = data
    //     state.getDetailData = true;
    //   }

    // });
    builder.addCase(getDetailCoalGetting.fulfilled, (state, action) => {
      const data = action.payload;
      // Update `production.pitToRom` with `subRowKey`
      const updatedPitToRom = data.production.pitToRom.map((item, index) => ({
        ...item,
        subRows: item.subRows.map((subrow, rowIndex) => ({
          ...subrow,
          subRowKey: `${index}_${rowIndex}` // Add `subRowKey` dynamically
        })),
      }));

      // Update the `production.pitToRom` in the `data` object
      data.production.pitToRom = updatedPitToRom;
      console.log("updatedPitToRom", data)
      // Log to verify the changes
      //  console.log("Updated action.payload", data);

      // Update state if `updatedPitToRom` exists
      if (updatedPitToRom) {
        state.coalGettingDetail = data; // Ensure the state gets updated with the modified data
        state.getDetailData = true;
      }
    });

    builder.addCase(getDetailCoalGetting.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid add data';
    });

    builder.addCase(getDetailCoalGettingUpdateAt.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDetailCoalGettingUpdateAt.fulfilled, (state, action) => {
      state.coalGettingDetail.updatedAt = action.payload?.updatedAt;
    });
    builder.addCase(getDetailCoalGettingUpdateAt.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid add data';
    });

    builder.addCase(getLatestProductionByContractorName.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLatestProductionByContractorName.fulfilled, (state, action) => {
      state.coalGettingDetail.production.pitToRom = action.payload.production;
      state.coalGettingDetail.production.getLatest = true;
      state.coalGettingDetail.pit = action.payload.pit;
      //  console.log("action.payload.pit", action.payload.pit)
      //state.loading = false
    });
    builder.addCase(getLatestProductionByContractorName.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid add data';
    });

    builder.addCase(getCoalBcm.pending, (state, action) => {
      state.bcmSummary.loading = true;
    });
    builder.addCase(getCoalBcm.fulfilled, (state, action) => {
      state.bcmSummary.loading = false;
      state.bcmSummary.records = action?.payload;

      state.bcmSummary.loading = false;
      state.bcmSummary.records = action?.payload;
      state.bcmSummaryDetailBar.records = action?.payload;

      const coalGettingData = JSON.parse(JSON.stringify(action.payload)) || [];
      const transformData = addDataChartContractor(addDataChartPit(coalGettingData));
      const mergedDataByDay = transformData.reduce((acc, curr) => {
        if (acc[curr.day]) {
          acc[curr.day].detail = [...acc[curr.day].detail, ...curr.detailList];
        } else {
          acc[curr.day] = curr;
        }
        return acc;
      }, {});

      state.bcmSummaryDetail.records = mergedDataByDay;
    });
    builder.addCase(getCoalBcm.rejected, (state, action) => {
      state.bcmSummary.loading = false;
    });
    // builder.addCase(getCoalBcmDetailBar.pending, (state, action) => {
    //   state.bcmSummaryDetailBar.loading = true;
    // });
    // builder.addCase(getCoalBcmDetailBar.fulfilled, (state, action) => {
    //   state.bcmSummaryDetailBar.loading = false;
    //   state.bcmSummaryDetailBar.records = action?.payload;
    // });
    // builder.addCase(getCoalBcmDetailBar.rejected, (state, action) => {
    //   state.bcmSummaryDetailBar.loading = false;
    // });

    builder.addCase(getPitOptions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPitOptions.fulfilled, (state, action) => {
      const data = action.payload.list || [];
      const filtered = data.filter((item) => item.entityStatus === 1 && item.location.area.name === "Pit");
      const options = filtered.reduce((unique, item) => {
        if (unique.findIndex(obj => obj.value === item.location.name) === -1) {
          unique.push({ value: item.location.name, label: item.location.name });
        }
        return unique;
      }, []);
      state.coalGetting.options.pit = options;
    });
    builder.addCase(getPitOptions.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getContractorOptions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getContractorOptions.fulfilled, (state, action) => {
      const data = action.payload || [];
      const options = data.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });

      if (options.length > 0) {
        options.unshift({ value: '', label: 'All' });
      }
      state.coalGetting.options.contractor = options;
    });
  },
});

export const coalGettingSelector = (state) => state.coalGetting?.coalGetting;
export const coalGettingDetailSelector = (state) => state.coalGetting?.coalGettingDetail;
export const coalGettingDocumentSelector = (state) => state.coalGetting?.documentList;
export const coalGettingVersionList = (state) => state.coalGetting?.versionList;
export const coalGettingPayloadSelector = (state) => state.payloadLatest;
export const wasteActiveIdDetailSelector = (state) => state.coalGetting?.activeIdDetail;
export const coalGettingIsGetDetailSelector = (state) => state.coalGetting?.getDetailData;
export const coalGettingBcm = (state) => state.coalGetting?.bcmSummary;
export const coalBcmDetail = (state) => state.coalGetting?.bcmSummaryDetail;
export const coalGettingBcmDetailBar = (state) => state.coalGetting?.bcmSummaryDetailBar;

export const {
  getCoalGettingDetail,
  activeIdDetail,
  addActualTotalRain,
  addDataTableProduction,
  addDataTableDropdownProduction,
  onChangeTableProduction,
  onChangeTotalPitToPort,
  addDataTableMaintenance,
  onChangeTableMaintenance,
  onDeleteRowTableMaintenance,
  onChangeTableDateSlippery,
  onChangeTableDateSlipperyCH,
  onChangePitWatch,
  onDeleteRowTableSlippery,
  onDeleteRowTableProduction,
  createCoalGetting,
  clearCoalGettingDetail,
  mappingMaterialDetailPage,
  onChangeDocumentCoal,
  onResetDocument,
  onAddTableSlippery,
  onChangePit,
  onChangeReportDate,
  onResetCoalDetail,
  addUploadDataTableProduction,
  onResetCoalBCM,
  onResetCoalBCMDetail,
  addOlderPayloadList
} = coalGettingSlice.actions;

export default coalGettingSlice.reducer;
